"use strict";
// import { gsap } from "gsap";
// import $ from "jquery";
Object.defineProperty(exports, "__esModule", { value: true });
const post_filter_1 = require("./post-filter");
const menu_1 = require("./menu");
const sliders_1 = require("./sliders");
/**
 * globalVariables variabili globali utilizzate all'interno del sito
 */
let globalVariables = {
    lastKnownScrollPosition: 0,
    headerShadowTheshold: 50,
    faqDataTargetPrefix: "#collapse-",
    // TODO qui possono andarci altre proprietà utili
    // dopo averle prima definite nell'interfaccia `GlobalVariables` in models.ts
};
// al caricamento completo della pagina si chiama
// la funzione onDocumentLoaded
document.addEventListener("DOMContentLoaded", function () {
    onDocumentLoaded();
});
// $(() => {
// 	onDocumentLoaded();
// });
/**
 * chiamata quando viene finito di caricare il document
 */
function onDocumentLoaded() {
    // // si inizializzano gli slider
    (0, sliders_1.initializePhotogallerySlider)();
    (0, sliders_1.initializeRecensioniSlider)();
    (0, sliders_1.initializeHomeSlider)();
    (0, sliders_1.initializeLavoriSlider)();
    (0, sliders_1.initializeChiSiamoVantaggiSlider)();
    (0, post_filter_1.addBlogFilterListener)();
    (0, menu_1.initializeMenuToggle)();
    // // si registra il listener per lo scroll
    document.addEventListener("scroll", (ev) => {
        doOnScroll(ev);
    });
    // enableThirdLevelMenu();
    initializeServiziTabListener();
}
/**
 * Chiamato allo scroll della pagina
 *
 * @param ev {@link Event}
 */
function doOnScroll(ev) {
    const oldScrollPosition = globalVariables.lastKnownScrollPosition;
    const newScrollPosition = window.scrollY;
    // per ottimizzazione si chiama changeHeaderOnScroll solo quando
    // si oltrepassa la soglia globalVariables.headerShadowTheshold definita
    if ((oldScrollPosition > globalVariables.headerShadowTheshold &&
        newScrollPosition < globalVariables.headerShadowTheshold) ||
        (oldScrollPosition <= globalVariables.headerShadowTheshold &&
            newScrollPosition >= globalVariables.headerShadowTheshold)) {
        // changeHeaderProperties(newScrollPosition >= globalVariables.headerShadowTheshold);
        if (newScrollPosition > globalVariables.headerShadowTheshold) {
            document.querySelector("body").classList.add("scrolled");
        }
        else {
            document.querySelector("body").classList.remove("scrolled");
        }
    }
    // NOTA: qui di seguito possono andarci altri metodi da chiamare allo scroll
    // si aggiorna lastKnownScrollPosition
    globalVariables.lastKnownScrollPosition = newScrollPosition;
}
// /**
//  * configura la modal della gallery mettendoci dentro l'immagine specifica da visualizzare
//  */
// function configureGalleryModal() {
// 	const modalEls = document.querySelectorAll('.galleriaLink');
// 	modalEls.forEach(el => {
// 		// ad ogni link associo event listener al click
// 		el.addEventListener('click', (ev: Event) => {
// 			// recupero dal link html l'attributo data-url
// 			const urlImg = (ev.currentTarget as HTMLLinkElement).getAttribute('data-url');
// 			if (!urlImg) {
// 				console.error('configureGalleryModal missing urlImg');
// 				return;
// 			}
// 			// metto la url ricavata nell'img dentro la modal
// 			const imgEl = document.querySelector('#imgModal');
// 			if (!imgEl) {
// 				console.error('configureGalleryModal missing imgEl');
// 				return;
// 			}
// 			(imgEl as HTMLImageElement).src = urlImg;
// 		})
// 	})
// }
function initializeServiziTabListener() {
    const tablinks = document.querySelectorAll(".tablinks");
    if (tablinks) {
        tablinks.forEach((tab) => {
            tab.addEventListener("click", (evt) => openServiziTab(evt));
        });
        tablinks[0].className += " active";
        const tabName = tablinks[0].dataset["id"];
        if (tabName) {
            const activeTab = document.querySelector("#" + tabName);
            if (activeTab) {
                activeTab.style.display = "block";
            }
        }
    }
}
function openServiziTab(evt) {
    // Get all elements with class="tabcontent" and hide them
    const tabcontent = document.getElementsByClassName("tabcontent");
    [...tabcontent].forEach((content) => {
        content.style.display = "none";
    });
    // Get all elements with class="tablinks" and remove the class "active"
    const tablinks = document.querySelectorAll(".tablinks");
    tablinks.forEach((tab) => {
        tab.className = tab.className.replace(" active", "");
    });
    // Show the current tab, and add an "active" class to the button that opened the tab
    const target = evt.currentTarget;
    if (target) {
        const tabName = target.dataset["id"];
        if (tabName) {
            target.className += " active";
            const activeTab = document.querySelector("#" + tabName);
            if (activeTab) {
                activeTab.style.display = "block";
            }
        }
    }
}
