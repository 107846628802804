"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.addBlogFilterListener = void 0;
/**
 * Aggiunge listener ad avento di click sulle checkbox di filtro per il blog
 */
function addBlogFilterListener() {
    // si ricavano tutte le checkbox del filtro del blog
    const filtri = document.querySelectorAll('.cat-list_item');
    filtri === null || filtri === void 0 ? void 0 : filtri.forEach(filtro => {
        // ad ognuna si applica event listener al check
        filtro.addEventListener('change', (ev => {
            // si chiama la funzione per filtrare i post
            blogApplyPostFilter(filtro.value);
        }));
    });
}
exports.addBlogFilterListener = addBlogFilterListener;
/**
 * chiamato per filtrare i post del blog
 * Nasconde i post che non hanno la categoria selezionata
 */
function blogApplyPostFilter(categoryName) {
    const label = document.querySelectorAll('.cat-list label');
    label[0].classList.add('cat-list-normal');
    // si ricavano tutti i contenitori dei post preview
    const postPreviews = document.querySelectorAll('.post_preview');
    postPreviews.forEach(el => {
        // si annullano eventuali filtri precedenti
        el.classList.remove('d-none');
        // si nascondono i post con categoria diversa da quella selezionata
        // aggiungendo una classe con display none
        if (!el.classList.contains(categoryName)) {
            el.classList.add('d-none');
        }
    });
}
